import { FeatureList, FeatureListProps } from "./index"

export default {
  title: "Components/FeatureList",
  component: FeatureList,
  args: {
    features: [
      {
        title: "KFW 55-EFFIZIENZHAUS",
        text: "Nachhaltiger und energieeffizienter KfW-55-Standard",
      },
      {
        title: "Leben im Grünen",
        text: "Blickachsen ins Grüne aus allen Gebäudeteilen sowie Gartennutzung",
      },
      {
        title: "Ausstattung",
        text: "Gehobene Innenausstattung inkl. Fußbodenheizung in allen Wohnungen",
      },
      {
        title: "E-Mobility",
        text: "4 E-Mobility-Ladesäulen für Elektrofahrzeuge",
      },
      {
        title: "Tiefgarage",
        text: "23 Tiefgaragenstellplätze; für jede Wohnung ist mindestens ein Platz vorgesehen",
      },
      {
        title: "Barrierefrei",
        text: "Aufzug von der Tiefgarage barrierefrei in alle Wohnetagen",
      },
    ],
  },
}

export const Base = (args: FeatureListProps) => {
  const { features } = args
  return <FeatureList {...{ features }} />
}
