import { Link } from "gatsby"
import "twin.macro"
import { ArrowRightIcon } from "@heroicons/react/solid"
import { GatsbyLayout } from "../../gatsby-components"

export const NotFound = () => {
  return (
    <GatsbyLayout>
      <div tw="flex flex-col items-center justify-center min-height[80vh] py-32">
        <span tw="inline-block text-gold">404 Fehler</span>
        <h1 tw="mt-2 text-4xl font-display font-extrabold text-gray-900 tracking-tight sm:text-5xl">
          Seite nicht gefunden.
        </h1>
        <p tw="mt-2 text-base text-gray-500">
          Es tut uns Leid, aber die von Ihnen gesuchte Seite existiert nicht
        </p>
        <Link to="/" tw="text-gold flex items-center mt-6 hover:underline">
          Zur Startseite <ArrowRightIcon tw="w-4 h-auto ml-2" />
        </Link>
      </div>
    </GatsbyLayout>
  )
}
