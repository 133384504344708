import "twin.macro"
import { StaticImage } from "gatsby-plugin-image"
import {
  GatsbyLayout,
  GatsbyContactPerson,
  GatsbyContactForm,
  Gallery,
  GatsbyCondoList,
  LivingInGreenBanner,
  ArriveBanner,
} from "../../gatsby-components"
import { Section } from "../../layout"
import {
  TopContent,
  FeatureList,
  Architecture,
  Card,
  CardImage,
  Banner,
  CardContent,
} from "../../components"
import { FrontPageHero } from "./_front-page-hero"
import { Button, ExternalLink } from "../../elements"
import { FrontPageLocation } from "./_front-page-location"
import FeatureListStories from "../../components/feature-list/feature-list.stories"

export const FrontPageTemplate = () => (
  <GatsbyLayout>
    <FrontPageHero />
    <Section paddingTop paddingBottom>
      <TopContent title="Ihr neues Zuhause wartet auf Sie">
        <p>
          Mitten in Merzig-Hilbringen entsteht das Neubauprojekt Quartier
          Hirtengarten. Es erwarten Sie 13 gehobene Eigentumswohnungen
          aufgeteilt auf die drei Gebäude A, B und C in einem eleganten
          Wohnensemble - ideal sowohl als Kapitalanlage, als auch für den
          Eigenbedarf.
        </p>
        <br />
        <p>
          Lebensqualität steht im Quartier Hirtengarten an erster Stelle. Von
          der energieeffizienten und verantwortungsvollen Bauweise bis zur
          Integration von Grün- und Balkonflächen für jede einzelne Wohnung
          wurde in jedem Detail auf Qualität und Ästhetik geachtet.
        </p>
        <br />
        <p>
          Beginnen Sie ein neues Kapitel in einem neuen Zuhause voller Wärme,
          Glück und Genuss – mitten im Herzen der Saar-Lor-Lux-Region im
          Quartier Hirtengarten.
        </p>
      </TopContent>
    </Section>
    <Section paddingBottom>
      <Gallery />
    </Section>
    {/* <StaticImage tw="w-full h-auto" src="../../images/milestones.jpg" alt="" /> */}
    <Banner />
    <Section paddingTop>
      <FeatureList features={FeatureListStories.args.features} />
    </Section>
    <Section paddingTop paddingBottom>
      <Architecture
        Image={
          <StaticImage
            tw="object-cover"
            src="../../images/architecture.jpg"
            alt="architecture of a house"
          />
        }
      />
    </Section>
    <Section paddingTop paddingBottom>
      <ArriveBanner />
    </Section>
    <Section paddingTop paddingBottom>
      <GatsbyCondoList />
    </Section>
    <Section hasBG paddingTop paddingBottom>
      <FrontPageLocation />
    </Section>
    <Section paddingTop paddingBottom>
      <div
        tw="relative w-full pt-10
  container max-w-screen-xl! xl:px-0!"
      >
        <Card>
          <CardImage tw="transform desktop:translate-x-4 -translate-y-4 desktop:translate-y-4">
            <StaticImage src="../../images/download.jpg" alt="" />
          </CardImage>
          <CardContent tw="pt-0! desktop:(p-4! pl-24!)">
            <h2 tw="uppercase pb-5 text-center tracking-wider leading-none text-xl desktop:text-3xl">
              Infobroschüre zum Download
            </h2>
            <p tw="mt-2 desktop:mt-4 text-center sm:pt-4">
              Hier finden Sie mehr Informationen über das Quartier Hirtengarten,
              Lage und Region zum Download, Ausdrucken und Weiterversenden.
            </p>
            <div tw="mt-4 desktop:(mt-auto pt-4) mx-10 ">
              <ExternalLink
                tw="flex justify-center"
                href="/downloads/ImageBroschuereLayout.pdf"
              >
                <Button tw="w-full mx-10">Download Broschüre</Button>
              </ExternalLink>
            </div>
          </CardContent>
        </Card>
      </div>
    </Section>
    {/* <Section paddingTop paddingBottom>
      <WelcomeBanner />
    </Section> */}
    {/* Contact Sections */}
    <Section hasBG paddingTop>
      <GatsbyContactForm />
    </Section>
    <Section hasBG paddingTop paddingBottom>
      <GatsbyContactPerson />
    </Section>
    <Section paddingTop paddingBottom>
      <LivingInGreenBanner />
    </Section>
  </GatsbyLayout>
)
