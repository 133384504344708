// @ts-ignore
import React from "react"
import { SEO } from "../components"
import { NotFound } from "../gatsby-templates"

const NotFoundPage = () => (
  <>
    <SEO title="404: Seite nicht gefunden" />
    <NotFound />
  </>
)

export default NotFoundPage
