import { StaticImage } from "gatsby-plugin-image"
import { Hero } from "../../components"

export const FrontPageHero = () => <Hero Image={FrontPageHeroBG} />

const FrontPageHeroBG = (
  <StaticImage src="../../images/hero.jpg" alt="Quartier Hirtengarten" />
)

/* <Hero label="Provisionsfrei" Image={FrontPageHeroBG} /> */
