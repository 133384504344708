import tw, { styled } from "twin.macro"

export const Section = styled.section<SectionProps>`
  ${({ paddingTop, paddingBottom, hasBG }) => [
    paddingTop && tw`pt-8 md:(pt-16)`,
    paddingBottom && tw`pb-8 md:(pb-16)`,
    hasBG && tw`bg-gray-100`,
  ]}
`

export type SectionProps = {
  paddingTop?: boolean
  paddingBottom?: boolean
  hasBG?: boolean
}
